import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import { Input } from "@progress/kendo-react-inputs";
// ES2015 module syntax
import { ListView, ListViewHeader, ListViewFooter } from "@progress/kendo-react-listview";
import Nav from "../components/Nav";
import Auth from "../services/Auth";
import { withRouter } from "react-router-dom";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS - My Sites";

const MyHeader = () => {
    return <ListViewHeader style={{ color: "rgb(160, 160, 160)", fontSize: 14 }} className="pl-3 pb-2 pt-2"></ListViewHeader>;
};

const MyFooter = () => {
    return <ListViewFooter style={{ color: "rgb(160, 160, 160)", fontSize: 14 }} className="pl-3 pb-2 pt-2"></ListViewFooter>;
};

const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
        <a href={item.link} title={item.description} className="list-group-item list-group-item-action">
            {item.siteName}
        </a>
    );
};

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            last_name: "",
            mail: "",
            description: "",
            password: "",
            repeatPassword: "",
            sites: [],
            sessions: [],
            redirect: false,
            loading: false,
        };
    }

    async componentDidMount() {
        let token = await Auth.getUserInfo();
        if (token) {
            this.getUserProfile();
            this.getUserSites();
            let redirectPath = Auth.getCookie("redirect");
            Auth.deleteCookie("redirect");
            if (redirectPath) {
                // this.props.history.push(redirectPath);
                window.location.replace(redirectPath); //this.props.history.push will not work here
            }
        } else {
            this.setState({
                redirect: true,
            });
        }
        document.title = pageTitle;
    }

    async getUserProfile() {
        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "GET",
            headers: authHeader
        };

        fetch("api/users/getUserInfo", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    name: result.name,
                    last_name: result.last_name,
                    mail: result.mail,
                    description: result.description,
                    role: result.role
                });
            })
            .catch((error) => {
                // Auth.deleteCookie("token");
                Auth.token = "";
                Auth.deleteCookie("redirect");
                console.log("Login Expired");
                this.setState({
                    redirect: true
                });
            });
    }

    async getUserSites() {
        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "GET",
            headers: authHeader
        };

        this.setState({ loading: true });

        fetch("api/users/getUserSites", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // Auth.setCookies(result.token, result.role, result.user_email);
                Auth.setVariables(result.token, result.role, result.user_email);
                this.setState({ sites: result.sites });
                this.setState({ sessions: result.sessions });
                this.setState({ loading: false });
            })
            .catch((error) => {
                // Auth.deleteCookie("token");
                Auth.token = "";
                Auth.deleteCookie("redirect");
                console.log("Login Expired");
                this.setState({ redirect: true });
                this.setState({ loading: false });
            });
    }

    async setUserInformation() {
        let { name, last_name, mail, description } = this.state;

        if (name.trim() === "" || last_name.trim() === "" || mail.trim() === "" || description.trim() === "") {
            window.alert("You must not leave empty fields");
            return;
        }

        if (!/\S+@\S+\.\S+/.test(mail)) {
            window.alert("Please enter a valid email.");
            return;
        }
        this.setState({ loading: true });
        const data = new FormData();
        data.append("givenname", name);
        data.append("sn", last_name);
        data.append("displayName", `${name} ${last_name}`);
        data.append("mail", mail);
        data.append("description", description);

        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "PUT",
            headers: authHeader,
            body: data,
        };

        fetch("api/users/changeUserInformation", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({ loading: false });
                window.alert(result.message);
                this.getUserProfile();
            })
            .catch((error) => {
                this.setState({ loading: false });
                // window.alert(error);
                console.log(error);
            });
    }

    handleChangeName = (event) => {
        this.setState({ name: event.value });
    };

    handleChangeLastName = (event) => {
        this.setState({ last_name: event.value });
    };

    handleChangeEmail = (event) => {
        this.setState({ mail: event.value });
    };

    handleChangeDescription = (event) => {
        this.setState({ description: event.value });
    };

    render() {
        const { name, last_name, mail, description, loading, redirect, sites } = this.state;

        if (redirect) {
            this.props.history.push(baseUrl);
        }

        return (
            <div>
                <Nav />
                <div className="col-xs-12 col-md-6 offset-md-3">
                    <br />
                    <div className="card">
                        {loading && (
                            <div className="k-loading-mask">
                                <span className="k-loading-text">Loading</span>
                                <div className="k-loading-image"></div>
                                <div className="k-loading-color"></div>
                            </div>
                        )}
                        <div className="card-block">
                            <form className={"k-form"} onSubmit={e => { e.preventDefault(); }}>
                                <fieldset>
                                    <legend style={{ textAlign: "center" }}>My Sites</legend>
                                    {sites.length === 0 && <p>You do not have access to any sites. Contact the <a href="mailto:helpdesk@apexcos.com?Subject=ARTEMIS%20Access%20Request">helpdesk</a></p>}
                                    {sites.length > 0 && (
                                        <div className="mb-3">
                                            <div className="list-group">
                                                <ListView data={sites} item={MyItemRender} style={{ width: "100%" }} header={MyHeader} footer={MyFooter} />
                                            </div>
                                        </div>
                                    )}
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Profile);
