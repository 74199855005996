import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";
import Nav from "../components/Nav";
import Auth from "../services/Auth";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Change Password";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            currentPassword: "",
            newPassword: "",
            newRepeatPassword: "",
            redirect: false,
            loading: false,
        };
    }

    async componentDidMount() {
        let token = await Auth.getUserInfo();
        if (token) {
            this.setState({ email: Auth.getUserMail() });
        } else {
            this.setState({ redirect: true });
        }
        document.title = pageTitle;
    }

    handleChangeCurrentPassword = (event) => {
        this.setState({ currentPassword: event.value });
    };

    handleChangeNewPassword = (event) => {
        this.setState({ newPassword: event.value });
    };

    handleChangeNewRepeatPassword = (event) => {
        this.setState({ newRepeatPassword: event.value });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        let { currentPassword, newPassword, newRepeatPassword } = this.state;
        if (currentPassword.trim() === "" || newPassword.trim() === "" || newRepeatPassword.trim() === "") {
            window.alert("You must not leave empty fields");
            return;
        }

        if (newPassword !== newRepeatPassword) {
            window.alert("New password and new repeat password must be the same");
            return;
        }

        if (!/^(?=.*?[a-z])(?=.*[A-Z])(?:(?=.*?[0-9])|(?=.*?[#?!@$%^&*_-])).{8,}$/.test(newPassword)) {
            window.alert("The new password does not comply with security policies");
            return;
        }

        const data = new FormData();
        data.append("currentPassword", currentPassword);
        data.append("newPassword", newPassword);
        this.setState({ loading: true });

        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "PUT",
            headers: authHeader,
            body: data,
        };

        fetch("api/users/changePassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                window.alert(result.message);
                this.setState({ loading: false });
                this.setState({
                    newPassword: "",
                    currentPassword: "",
                    newRepeatPassword: "",
                });
                this.props.history.push(baseUrl + "profile");
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        const { redirect, email, currentPassword, newRepeatPassword, newPassword, loading } = this.state;

        if (redirect) {
            this.props.history.push(baseUrl);
        }
        if (email !== "" && email.toLowerCase().indexOf("apexcos.com") > -1) {
            this.props.history.push(baseUrl);
        }

        return (
            <div style={{ height: "95%" }}>
                <Nav />
                <div className="login-center">
                    <div className="panel-login center">
                        {loading && (
                            <div className="k-loading-mask">
                                <span className="k-loading-text">Loading</span>
                                <div className="k-loading-image"></div>
                                <div className="k-loading-color"></div>
                            </div>
                        )}
                        <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                            <fieldset>
                                <h5>Change my password</h5>
                                <div className="mb-3">
                                    <Input type="password" label="Current Password" value={currentPassword} onChange={this.handleChangeCurrentPassword} />
                                </div>
                                <div className="mb-3">
                                    <Input type="password" label="New password" value={newPassword} onChange={this.handleChangeNewPassword} />
                                </div>
                                <div className="mb-3">
                                    <Input type="password" label="Repeat new password" value={newRepeatPassword} onChange={this.handleChangeNewRepeatPassword} />
                                </div>
                                <p
                                    style={{
                                        textAlign: "justify",
                                        fontSize: "12px",
                                        marginBottom: "0px",
                                    }}
                                >
                                    <strong>The passwords must meet the following minimum requirements:</strong>
                                    <br />
                                    Passwords used previously are not allowed
                                    <br />
                                    Not contain the user's account name or parts of the user's full name that exceed two consecutive characters.
                                    <br />
                                    Be at least eight characters in length.
                                    <br />
                                    Contain characters from three of the following four categories:
                                    <ul>
                                        <li>English uppercase characters (A through Z).</li>
                                        <li>English lowercase characters (a through z).</li>
                                        <li>Base 10 digits (0 through 9).</li>
                                        <li>Non-alphabetic characters (e.g. !, $, #, %).</li>
                                    </ul>
                                </p>
                            </fieldset>
                            <Button type="button" themeColor={"primary"} style={{ width: "100%", marginBottom: "5px" }} onClick={this.handleSubmit}>
                                Confirm
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ChangePassword);
