import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import "url-search-params-polyfill";
import parse from "html-react-parser";
import { isJwtExpired } from 'jwt-check-expiration';

import { Input, SliderLabel, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { Link, withRouter } from "react-router-dom";
import logo from "../assets/logo.png";
import Auth from "../services/Auth";
import { Label } from "reactstrap";
import { parseColor } from "@progress/kendo-drawing";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login";
//const homepage = "/loginuat";
const homepage = "/login";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            name: "",
            lastName: "",
            newEmail: "",
            site: "",
            reason: "",
            selected: 0,
            redirect: false,
            loading: false,
            sso: false,
            signUpMessage: "",
            token: ""
        };
    }

    async componentDidMount() {
        let token = await Auth.getUserInfo();
        if (token) {
            // this.state.redirect = true;
            this.setState({ redirect: true });
            this.setState({ token: token });
        }
        document.title = pageTitle;
    }

    handleChangeEmail = (event) => {
        this.setState({ email: event.value });

        if (this.validateEmail(event.value)) {
            this.setRedirectPathCookie();
            this.hasSSO(event.value);
        }
    };

    handleChangePassWord = (event) => {
        this.setState({ password: event.value });
    };

    handleChangeName = (event) => {
        this.setState({ name: event.value });
    };

    handleChangeLastName = (event) => {
        this.setState({ lastName: event.value });
    };

    handleChangeNewEmail = (event) => {
        this.setState({ newEmail: event.value });
        if (this.validateEmail(event.value)) {
            // console.log("Valid: " + event.value);
            this.hasSignUpMessage(event.value);
        } else {
            this.setState({ signUpMessage: "" });
        }
    };

    handleChangeSite = (event) => {
        this.setState({ site: event.value });
    };

    handleChangeReason = (event) => {
        this.setState({ reason: event.value });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    keyPressSignUp = (event) => {
        if (event.key === "Enter") {
            this.handleSubmitSignUp();
        }
    };

    handleSubmit = () => {
        let { email, password } = this.state;

        if (email.trim() === "" || password.trim() === "") {
            window.alert("You must not leave empty fields");
            return;
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            window.alert("Please enter a valid email.");
            return;
        }

        const data = new FormData();
        data.append("email", email);
        data.append("password", password);

        this.setState({ loading: true });

        fetch("api/users/login", {
            method: "POST",
            body: data,
        })
            .then((res) => res.json().then((data) => ({ status: res.status, response: data })))
            .then((r) => {
                if (r.status === 200) {
                    this.setState({ loading: false });

                    if (r.response.security === false) {
                        // Auth.setCookies(r.response.token, r.response.role, r.response.user_email);
                        Auth.setVariables(r.response.token, r.response.role, r.response.user_email);
                        this.props.history.push(baseUrl + "security-questions", {
                            user_email: r.response.user_email,
                            user_id: r.response.user_id,
                            challenge: "NO_QUESTIONS",
                            reset_token: r.response.reset_token,
                        });
                        return;
                    }

                    if (!r.response.active) {
                        if (r.response.challenge === "NO_DATA") {
                            if (r.response.user_email.toLowerCase().indexOf("apexcos.com") > -1) {
                                this.props.history.push(baseUrl + "security-questions", {
                                    user_email: r.response.user_email,
                                    user_id: r.response.user_id,
                                    challenge: r.response.challenge,
                                    reset_token: r.response.reset_token,
                                });
                            } else {
                                this.props.history.push(baseUrl + "reset-password", {
                                    user_email: r.response.user_email,
                                    user_id: r.response.user_id,
                                    challenge: r.response.challenge,
                                    reset_token: r.response.reset_token,
                                });
                            }
                        } else {
                            let queryString = window.location.search;
                            let redirectPath = "";
                            if (queryString) {
                                redirectPath = queryString;
                            }
                            this.props.history.push(baseUrl + "user-security-questions" + redirectPath, {
                                user_email: r.response.user_email,
                                user_id: r.response.user_id,
                                challenge: r.response.challenge,
                                reset_token: r.response.reset_token,
                            });
                        }
                    } else {
                        // Auth.setCookies(r.response.token, r.response.role, r.response.user_email);
                        Auth.setVariables(r.response.token, r.response.role, r.response.user_email);
                        // let queryString = window.location.search;

                        // if (queryString) {
                        //     let urlParams = new URLSearchParams(queryString);
                        //     // console.log("params: " + urlParams);
                        //     let redirectPath = urlParams.get("redirect") + window.location.hash;
                        //     if (redirectPath) {
                        //         window.location.href = window.location.origin + redirectPath;
                        //     } else {
                        //         this.props.history.push(baseUrl + "profile");
                        //     }
                        // } else {
                        //     //console.log('no string');
                        //     this.props.history.push(baseUrl + "profile");
                        // }
                        this.handleRedirect();
                    }
                } else {
                    console.log(r.response.message);
                    window.alert(r.response.message);
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    setRedirectPathCookie = () => {
        let queryString = window.location.search;
        if (queryString) {
            let urlParams = new URLSearchParams(queryString);
            // console.log("params: " + urlParams);
            let redirectPath = urlParams.get("redirect") + window.location.hash;
            if (redirectPath) {
                // window.location.href = window.location.origin + redirectPath;
                Auth.setCookie("redirect", redirectPath);
                this.props.history.push(baseUrl);
            } else {
                // this.props.history.push(baseUrl + "profile");
                Auth.deleteCookie("redirect");
            }
        }
    };

    handleRedirect = () => {
        let redirectPath = Auth.getCookie("redirect");
        if (redirectPath) {
            Auth.deleteCookie("redirect");
            window.location.replace(redirectPath);
        } else {
            this.props.history.push(baseUrl + "profile");
        }
    };

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    hasSSO = (email) => {
        if (email.toLowerCase().indexOf("apexcos.com") > -1) {
            // Quicker to hide password field before Ajax call if Apex email:
            this.setState({ sso: true });
        }
        var requestOptions = {
            method: "GET"
        };
        // base64 encode email to pass to API
        var base64Email = btoa(email);
        fetch("api/saml/emailDomain/" + base64Email, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // window.alert(result);
                if (result.valid) {
                    // let queryString = window.location.search;
                    // if (queryString) {
                    //     let urlParams = new URLSearchParams(queryString);
                    //     // console.log("params: " + urlParams);
                    //     let redirectPath = urlParams.get("redirect") + window.location.hash;
                    //     Auth.setCookie("redirect", redirectPath);
                    // }
                    this.setState({ sso: true });
                    this.setState({ loading: true });
                    var emailDomain = result.email_domain;
                    window.location.replace(homepage + "/api/saml/login/" + emailDomain); // redirects to sso urn; this.props.history.push will not work here
                } else {
                    this.setState({ sso: false });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
                this.setState({ sso: false });
                // window.alert("Error");
            });
    };

    hasSignUpMessage = (email) => {
        var requestOptions = {
            method: "GET"
        };
        fetch("api/users/emailDomain/" + email, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.valid) {
                    this.setState({ signUpMessage: result.signUpMessage });
                    // window.alert(result.signUpMessage);
                } else {
                    this.setState({ signUpMessage: "" });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.setState({ signUpMessage: "" });
                console.log(error);
                // window.alert("Error");
            });
    };

    handleSubmitSignUp = () => {
        let { name, lastName, newEmail, site, reason } = this.state;
        if (name.trim() === "" || lastName.trim() === "" || newEmail.trim() === "" || site.trim() === "") {
            window.alert("You forgot to enter some values.");
            return;
        }

        if (!/\S+@\S+\.\S+/.test(newEmail)) {
            window.alert("Please enter a valid email.");
            return;
        }

        const data = new FormData();
        data.append("name", name);
        data.append("lastName", lastName);
        data.append("email", newEmail);
        data.append("site", site);
        data.append("reason", reason);

        this.setState({ loading: true });

        fetch("api/users/requestNewUser", {
            method: "POST",
            body: data,
        })
            .then((res) => res.json().then((data) => ({ status: res.status, response: data })))
            .then((r) => {
                if (r.status === 200) {
                    window.alert(r.response.message);
                    this.setState({
                        name: "",
                        lastName: "",
                        newEmail: "",
                        site: "",
                        reason: "",
                    });
                    this.setState({ loading: false });
                } else {
                    window.alert(r.response.message);
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    handleSelect = (e) => {
        this.setState({ selected: e.selected });
    };

    render() {
        const { selected, email, password, name, lastName, newEmail, site, reason, loading, sso, redirect, token } = this.state;

        // if (this.state.redirect) {
        if (redirect) {
            // this.props.history.push(baseUrl + 'profile');
            // let queryString = window.location.search;
            // if (queryString) {
            //     let urlParams = new URLSearchParams(queryString);
            //     // console.log("params: " + urlParams);
            //     let redirectPath = urlParams.get("redirect") + window.location.hash;
            //     if (redirectPath) {
            //         Auth.deleteCookie("redirect");
            //         window.location.href = window.location.origin + redirectPath;
            //         //this.props.history.push(baseUrl + "profile?" + redirectPath);
            //     } else {
            //         this.props.history.push(baseUrl + "profile");
            //     }
            // } else {
            //     //console.log('no string');
            //     this.props.history.push(baseUrl + "profile");
            // }
            if (token !== "" && !isJwtExpired(token)) {
                this.handleRedirect();
            }
        } else {
            // let token = Auth.getUserInfo();
            if (token !== "" && isJwtExpired(token)) {
                // Auth.deleteCookie("token");
                Auth.token = "";
            }
        }

        return (
            <div className="login-center">
                <div className="panel-login center" style={{ marginTop: "3%" }}>
                    {loading && (
                        <div className="k-loading-mask" style={{ zIndex: 10000 }}>
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image" title="Loading"></div>
                            <div className="k-loading-color"></div>
                        </div>
                    )}

                    <TabStrip selected={selected} onSelect={this.handleSelect}>
                        <TabStripTab title="Login">
                            <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                                <fieldset>
                                    <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                                    <div className="mb-3">
                                        <Input type="email" label="Email" value={email} onChange={this.handleChangeEmail} />
                                    </div>
                                    {!sso && (
                                        <div className="mb-3">
                                            <Input onKeyDown={this.keyPress} type="password" label="Password" value={password} onChange={this.handleChangePassWord} />
                                        </div>
                                    )}
                                </fieldset>
                                {!sso && (
                                    <Button type="button" themeColor={"primary"} style={{ width: "100%", marginBottom: "5px" }} onClick={this.handleSubmit}>
                                        Login
                                    </Button>
                                )}
                                {!sso && (
                                    <Link to={`${process.env.PUBLIC_URL}/password`} style={{ textDecoration: "none" }}>
                                        <Button type="button" style={{ width: "100%" }}>
                                            Forgot your password?
                                        </Button>
                                    </Link>
                                )}
                            </form>
                        </TabStripTab>
                        <TabStripTab title="Sign up" onSelect={this.handleSelect}>
                            <form className={"k-form center"}>
                                <fieldset>
                                    <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                                    <div className="mb-3">
                                        <Input id="name_field" type="text" label="First Name" value={name} onChange={this.handleChangeName} />
                                    </div>
                                    <div className="mb-3">
                                        <Input type="text" label="Last Name" value={lastName} onChange={this.handleChangeLastName} />
                                    </div>
                                    <div className="mb-3">
                                        <Input type="email" label="Email" value={newEmail} onChange={this.handleChangeNewEmail} />
                                    </div>
                                    <div className="mb-3">
                                        <Input type="text" label="ARTEMIS site needing access" value={site} onChange={this.handleChangeSite} onKeyDown={this.keyPressSignUp} />
                                    </div>
                                    <div className="mb-3">
                                        <FloatingLabel editorValue={reason} label="Why do you need this access?">
                                            <TextArea value={reason} onChange={this.handleChangeReason} />
                                        </FloatingLabel>
                                    </div>
                                    {this.state.signUpMessage !== "" && (
                                        <div className="mb-3">
                                            <span className="error">{parse(this.state.signUpMessage)}</span>
                                        </div>
                                    )}
                                </fieldset>

                                <Button type="button" themeColor={"primary"} style={{ width: "100%" }} onClick={this.handleSubmitSignUp} disabled={this.state.signUpMessage !== ""}>
                                    Sign up
                                </Button>
                            </form>
                        </TabStripTab>
                    </TabStrip>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
