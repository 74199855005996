import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Reset Account";

class ResetAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,
            newPassword: "",
            newRepeatPassword: "",
            error: true,
        };
    }

    async componentDidMount() {
        await this.checkTokenReset();
        document.title = pageTitle;
    }

    async checkTokenReset() {
        const response = await fetch("api/users/checkResetToken/" + this.props.match.params.token);
        const data = await response.json();
        if (data.valid) {
            this.setState({ error: false });
            if (data.type === "NEW_USER" || data.type === "RESET_ACCOUNT") {
                if (data.user_email.toLowerCase().indexOf("apexcos.com") > -1) {
                    this.props.history.push(baseUrl + "security-questions", {
                        user_id: data.user_id,
                        user_email: data.user_email,
                        challenge: data.type,
                        reset_token: this.props.match.params.token,
                    });
                } else {
                    this.props.history.push(baseUrl + "reset-password", {
                        user_id: data.user_id,
                        user_email: data.user_email,
                        challenge: data.type,
                        reset_token: this.props.match.params.token,
                    });
                }
            } else {
                if (data.type === "RESET_PASSWORD") {
                    this.props.history.push(baseUrl + "user-security-questions", {
                        user_id: data.user_id,
                        user_email: data.user_email,
                        challenge: data.type,
                        reset_token: this.props.match.params.token,
                    });
                }
            }
        } else {
            window.alert("Password reset link is invalid or has expired");
        }
    }

    render() {
        const { newRepeatPassword, newPassword, error } = this.state;
        if (error) {
            return <div></div>;
        } else {
            return <div></div>;
        }
    }
}

export default withRouter(ResetAccount);
