import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Link, withRouter } from "react-router-dom";
import Nav from "../components/Nav";
import Auth from "../services/Auth";
import logo from "../assets/logo.png";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Email New User";

class EmailNewUser extends Component {
    constructor(props) {
        super(props);
        // this.props = props;
        this.state = {
            role: "",
            email: "",
            redirect: false,
            loading: false,
        };
    }

    async componentDidMount() {
        let token = await Auth.getUserInfo();
        if (token) {
            this.setState({ role: Auth.getUserRole() });
        } else {
            this.setState({ redirect: true });
        }
        document.title = pageTitle;
    }

    handleChangeEmail = (event) => {
        this.setState({ email: event.value });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        let { email } = this.state;

        const data = new FormData();
        data.append("to", email);

        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "POST",
            body: data,
            headers: authHeader
        };

        if (!/\S+@\S+\.\S+/.test(email)) {
            window.alert("Please enter a valid email.");
            return;
        }

        this.setState({ loading: true });

        fetch("api/users/requestNewUserEmail", requestOptions)
            .then((res) => res.json().then((data) => ({ status: res.status, response: data })))
            .then((result) => {
                if (result.status === 200) {
                    window.alert("Email sent to user: " + this.state.email);
                    this.setState({ email: "" });
                } else {
                    if (result.response.message) {
                        window.alert(result.response.message);
                    } else {
                        window.alert("There was a problem sending the email to the user");
                    }
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        const { role, email, loading, redirect } = this.state;

        if (redirect) {
            this.props.history.push(baseUrl);
        }
        if (role !== "" && role != "admin") {
            this.props.history.push(baseUrl);
        }

        return (
            <div style={{ height: "95%" }}>
                <Nav />
                <div className="login-center">
                    <div className="panel-login center">
                        {loading && (
                            <div className="k-loading-mask">
                                <span className="k-loading-text">Loading</span>
                                <div className="k-loading-image"></div>
                                <div className="k-loading-color"></div>
                            </div>
                        )}
                        <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                            <fieldset>
                                <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                                <div style={{ marginTop: "20px" }}>We will send an email with a link for the user to set up the account.</div>
                                <div className="mb-3">
                                    <Input id="emailInput" onKeyDown={this.keyPress} type="Email or user account" label="Email" value={email} onChange={this.handleChangeEmail} />
                                </div>
                            </fieldset>

                            <Button type="button" themeColor={"primary"} style={{ width: "100%", marginBottom: "5px" }} onClick={this.handleSubmit}>
                                Send
                            </Button>
                            <Link to={`${process.env.PUBLIC_URL}`} style={{ textDecoration: "none" }}>
                                <Button type="button" style={{ width: "100%" }}>
                                    Go back
                                </Button>
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

// export default EmailNewUser;
export default withRouter(EmailNewUser);
