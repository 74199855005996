import React, { Component } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { FloatingLabel } from "@progress/kendo-react-labels";
import Auth from "../services/Auth";
import { withRouter } from "react-router-dom";
import logo from "../assets/logo.png";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Security Questions";

class UserSecurityQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            selectQuestion: null,
            answer: "",
            redirect: false,
            email: "",
            loading: false,
            showPassword: false
        };
    }

    componentDidMount() {
        this.getUserSecurityQuestions();
        document.title = pageTitle;
    }

    getUserSecurityQuestions() {
        const data = new FormData();
        if (this.props.location.state.challenge === "IP_ADDRESS") {
            //console.log(this.props);
            data.append("user_id", this.props.location.state.user_id);
            data.append("reset_token", this.props.location.state.reset_token);
        }
        if (this.props.location.state.challenge === "EXPIRED_ACCOUNT" || this.props.location.state.challenge === "LOCKED_ACCOUNT") {
            data.append("user_id", this.props.location.state.user_id);
            data.append("reset_token", this.props.location.state.reset_token);
        }
        if (this.props.location.state.challenge === "RESET_PASSWORD") {
            data.append("user_id", this.props.location.state.user_id);
            data.append("reset_token", this.props.location.state.reset_token);
            data.append("user_email", this.props.location.state.user_email);
        }
        data.append("challenge", this.props.location.state.challenge);

        var requestOptions = {
            method: "POST",
            body: data,
        };
        this.setState({ loading: true });

        fetch("api/users/getUserQuestions", requestOptions)
            .then((response) => response.json())
            .then((questions) => {
                this.setState({ loading: false });
                this.setState({ questions });
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    }

    onChangeQuestion = (event) => {
        this.setState({
            selectQuestion: event.target.value,
        });
    };

    handleChangeAnswer = (event) => {
        this.setState({ answer: event.value });
    };

    resetAccount = () => {
        const data = new FormData();

        data.append("reset_token", this.props.location.state.reset_token);
        data.append("to", this.props.location.state.user_email);

        var requestOptions = {
            method: "POST",
            body: data,
        };
        this.setState({ loading: true });
        fetch("api/users/requestResetAccount", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({ loading: false });
                alert("We have sent you an email with instructions to reset your account");
            });
    };

    togglePassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleConfirm();
        }
    };

    handleConfirm = () => {
        let { selectQuestion, answer } = this.state;
        if (!selectQuestion) {
            alert("Please select a security question.");
            return;
        }

        if (answer.trim() === "") {
            alert("Please enter a security question answer.");
            return;
        }

        const data = new FormData();

        data.append("reset_token", this.props.location.state.reset_token);
        data.append("user_id", this.props.location.state.user_id);
        data.append("user_email", this.props.location.state.user_email);
        data.append("question", selectQuestion.id);
        data.append("answer", answer);
        data.append("challenge", this.props.location.state.challenge);

        var requestOptions = {
            method: "POST",
            body: data,
        };

        this.setState({ loading: true });

        fetch("api/users/checkSecurityQuestions", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({ loading: false });
                if (this.props.location.state.challenge === "RESET_PASSWORD") {
                    if (result.valid === true) {
                        this.props.history.push(baseUrl + "reset-password/", {
                            reset_token: this.props.location.state.reset_token,
                            user_id: this.props.location.state.user_id,
                            user_email: this.props.location.state.user_email,
                            challenge: this.props.location.state.challenge,
                        });
                    } else {
                        window.alert("The answer provided does not match the one recorded in our system. Please check your spelling and try again.");
                    }
                    //this.props.history.push(baseUrl + "reset-password/", { reset_token: this.props.location.state.reset_token, user_id: this.props.location.state.user_id, challenge: this.props.location.state.challenge, user_email: this.props.location.state.user_email });
                } else {
                    if (result.valid === true) {
                        // Auth.setCookies(result.token, result.role, result.user_email);
                        Auth.setVariables(result.token, result.role, result.user_email);
                        let queryString = window.location.search;

                        if (queryString) {
                            let urlParams = new URLSearchParams(queryString);
                            console.log("params: " + urlParams);
                            if (urlParams.get("redirect")) {
                                //window.location.href = window.location.origin + urlParams.get('redirect');
                                window.location.href = urlParams.get("redirect");
                            } else {
                                this.props.history.push(baseUrl + "profile");
                            }
                        } else {
                            this.props.history.push(baseUrl + "profile");
                        }
                    } else {
                        window.alert("The answer provided does not match the one recorded in our system. Please check your spelling and try again.");
                    }
                }
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        const { questions, selectQuestion, answer, redirect, showPassword } = this.state;

        if (redirect) {
            this.props.history.push(baseUrl);
        }

        var blockMessage = "";

        switch (this.props.location.state.challenge) {
            case "LOCKED_ACCOUNT":
                blockMessage = "It seems that your account is locked. Please answer the question below to unlock your account";
                break;
            case "EXPIRED_ACCOUNT":
                blockMessage = "It seems that your account has expired. Please answer the question below to unlock your account";
                break;
            case "IP_ADDRESS":
                blockMessage = "Apex does not recognize this device. If this is the first time your logging in from this location, please answer the question below";
                break;
            case "RESET_PASSWORD":
                blockMessage = "In order to reset your password, please answer the question below";
                break;
            default:
                blockMessage = "";
        }

        return (
            <div className="login-center">
                <div className="panel-login-wide center" style={{ marginTop: "4%" }}>
                    {this.state.loading && (
                        <div className="k-loading-mask">
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image"></div>
                            <div className="k-loading-color"></div>
                        </div>
                    )}
                    <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                        <fieldset>
                            <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                            <div style={{ marginTop: "20px" }}>{blockMessage}</div>
                            <div className="mb-3">
                                <DropDownList label="Choose a security question" data={questions} textField="description" dataItemKey="id" value={selectQuestion} onChange={this.onChangeQuestion} />
                            </div>
                            <div className="mb-2">
                                <div className="input-group">
                                    <Input type={showPassword ? "text" : "password"} className="form-control" placeholder="Security question answer" onKeyDown={this.keyPress} value={answer} onChange={this.handleChangeAnswer} />
                                    <div class="input-group-append">
                                        <Button className="btn" type="button" onClick={this.togglePassword} title="Toggle answer display"><li class="fas fa-eye"></li></Button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <Button type="button" themeColor={"primary"} style={{ width: "100%" }} onClick={this.handleConfirm}>
                            Confirm
                        </Button>
                        <br />
                        <br />
                        <p>Cannot remember any of your security question answers?</p>
                        <Button type="button" style={{ width: "100%" }} onClick={this.resetAccount}>
                            Reset your account
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(UserSecurityQuestions);
