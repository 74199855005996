import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { withRouter } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Reset Password";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,
            newPassword: "",
            newRepeatPassword: "",
            error: false,
            loading: false,
        };
    }

    componentDidMount() {
        document.title = pageTitle;
    }

    handleChangeNewPassword = (event) => {
        this.setState({ newPassword: event.value });
    };

    handleChangeNewRepeatPassword = (event) => {
        this.setState({ newRepeatPassword: event.value });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        let { newPassword, newRepeatPassword, token } = this.state;
        if (newPassword.trim() === "" || newRepeatPassword.trim() === "") {
            window.alert("You must not leave empty fields");
            return;
        }

        if (newPassword !== newRepeatPassword) {
            window.alert("New password and new repeat password must be the same");
            return;
        }

        if (!/^(?=.*?[a-z])(?=.*[A-Z])(?:(?=.*?[0-9])|(?=.*?[#?!@$%^&*_-])).{8,}$/.test(newPassword)) {
            window.alert("The new password does not comply with security policies");
            return;
        }

        const data = new FormData();
        data.append("newPassword", newPassword);
        data.append("reset_token", this.props.location.state.reset_token);
        data.append("user_id", this.props.location.state.user_id);
        data.append("user_email", this.props.location.state.user_email);
        data.append("challenge", this.props.location.state.challenge);
        var requestOptions = {
            method: "PUT",
            body: data,
        };

        this.setState({ loading: true });

        fetch("api/users/resetPassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                window.alert(result.message);
                this.setState({ loading: false });
                if (this.props.location.state.challenge === "NO_DATA" ||
                    this.props.location.state.challenge === "NEW_USER" ||
                    this.props.location.state.challenge === "RESET_ACCOUNT") {
                    this.props.history.push(baseUrl + "security-questions", {
                        user_id: this.props.location.state.user_id,
                        challenge: this.props.location.state.challenge,
                        user_email: this.props.location.state.user_email,
                        reset_token: this.props.location.state.reset_token,
                    });
                } else {
                    this.props.history.push(baseUrl);
                }
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        const { newRepeatPassword, newPassword, error } = this.state;
        if (error) {
            return <div></div>;
        } else {
            var title = "";
            var message = "";
            switch (this.props.location.state.challenge) {
                case "NEW_USER":
                    title = "Set your password";
                    message = "New users must set their password upon logging in for the first time";
                    break;
                case "NO_DATA":
                    title = "Set your password";
                    message = "New users must set their password upon logging in for the first time";
                    break;
                case "RESET_ACCOUNT":
                    title = "Set your password";
                    message = "Please enter a new password for your account";
                    break;
                case "RESET_PASSWORD":
                    title = "Reset your expired password";
                    message = "Your password has expired. Please set your new password. Please note that you cannot use an old password";
                    break;
                default:
                    title = "";
                    message = "";
            }

            return (
                <div className="login-center">
                    <div className="panel-login center">
                        {this.state.loading && (
                            <div className="k-loading-mask">
                                <span className="k-loading-text">Loading</span>
                                <div className="k-loading-image"></div>
                                <div className="k-loading-color"></div>
                            </div>
                        )}
                        <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                            <fieldset>
                                <h5>{title}</h5>
                                <p>{message}</p>
                                <div className="mb-3">
                                    <Input type="password" label="New password" value={newPassword} title="" onChange={this.handleChangeNewPassword} />
                                </div>
                                <div className="mb-3">
                                    <Input type="password" label="Repeat new password" value={newRepeatPassword} onChange={this.handleChangeNewRepeatPassword} />
                                </div>
                                <p
                                    style={{
                                        textAlign: "justify",
                                        fontSize: "12px",
                                        marginBottom: "0px",
                                    }}
                                >
                                    <strong>The passwords must meet the following minimum requirements:</strong>
                                    <br />
                                    Passwords used previously are not allowed
                                    <br />
                                    Not contain the user's account name or parts of the user's full name that exceed two consecutive characters.
                                    <br />
                                    Be at least eight characters in length.
                                    <br />
                                    Contain characters from three of the following four categories:
                                    <ul>
                                        <li>English uppercase characters (A through Z).</li>
                                        <li>English lowercase characters (a through z).</li>
                                        <li>Base 10 digits (0 through 9).</li>
                                        <li>Non-alphabetic characters (e.g. !, $, #, %).</li>
                                    </ul>
                                </p>
                            </fieldset>
                            <Button type="button" themeColor={"primary"} style={{ width: "100%", marginBottom: "5px" }} onClick={this.handleSubmit}>
                                Confirm
                            </Button>
                        </form>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(ResetPassword);
