import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import Auth from "../services/Auth";
import { withRouter } from "react-router-dom";
import logo from "../assets/logo.png";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Security Questions";

class SecurityQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            selectQuestionOne: null,
            answerOne: "",
            selectQuestionTwo: null,
            answerTwo: "",
            selectQuestionThree: null,
            answerThree: "",
            redirect: false,
            loading: false,
        };
    }

    async componentDidMount() {
        var token = await Auth.getUserInfo();
        if (token || this.props.location.state.challenge) {
            this.getSecurityQuestions();
        } else {
            this.setState({
                redirect: true,
            });
        }
        document.title = pageTitle;
    }

    getSecurityQuestions() {
        fetch("api/users/listQuestions").then((response) => {
            response.json().then((data) => {
                this.setState({
                    questions: data,
                });
            });
        });
    }

    onChangeQuestionOne = (event) => {
        this.setState({
            selectQuestionOne: event.target.value,
        });
    };

    onChangeQuestionTwo = (event) => {
        this.setState({
            selectQuestionTwo: event.target.value,
        });
    };

    onChangeQuestionThree = (event) => {
        this.setState({
            selectQuestionThree: event.target.value,
        });
    };

    handleChangeAnswerOne = (event) => {
        this.setState({ answerOne: event.value });
    };

    handleChangeAnswerTwo = (event) => {
        this.setState({ answerTwo: event.value });
    };

    handleChangeAnswerThree = (event) => {
        this.setState({ answerThree: event.value });
    };

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleConfirm();
        }
    };

    handleConfirm = async () => {
        let { selectQuestionOne, selectQuestionTwo, selectQuestionThree, answerOne, answerTwo, answerThree } = this.state;
        if (!selectQuestionOne || !selectQuestionTwo || !selectQuestionThree) {
            alert("Please select a security question.");
            return;
        }

        if (answerOne.trim() === "" || answerTwo.trim() === "" || answerThree.trim() === "") {
            alert("Please enter a security question response.");
            return;
        }

        const data = new FormData();
        data.append("question_one", selectQuestionOne.id);
        data.append("answer_question_one", answerOne);
        data.append("question_two", selectQuestionTwo.id);
        data.append("answer_question_two", answerTwo);
        data.append("question_three", selectQuestionThree.id);
        data.append("answer_question_three", answerThree);
        data.append("user_id", this.props.location.state.user_id);
        data.append("user_email", this.props.location.state.user_email);
        data.append("reset_token", this.props.location.state.reset_token);

        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "POST",
            headers: authHeader,
            body: data,
        };

        this.setState({ loading: true });

        fetch("api/users/saveUserQuestions", requestOptions)
            .then((res) => res.json().then((data) => ({ status: res.status, response: data })))
            .then((result) => {
                if (result.status === 200) {
                    // console.log(result);
                    window.alert("Your questions have been saved");
                    this.setState({ loading: false });
                    // Auth.setCookies(result.response.token, result.response.role, result.response.user_email);
                    this.props.history.push(baseUrl);
                } else {
                    window.alert("There was a problem saving your questions");
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.log(error);
                //window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        //console.log("rendering");
        const { questions, selectQuestionOne, selectQuestionTwo, selectQuestionThree, answerOne, answerTwo, answerThree, redirect } = this.state;
        // console.log(questions);

        if (redirect) {
            this.props.history.push(baseUrl);
        }

        return (
            <div className="login-center">
                <div className="panel-login center" style={{ marginTop: "4%" }}>
                    {this.state.loading && (
                        <div className="k-loading-mask">
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image"></div>
                            <div className="k-loading-color"></div>
                        </div>
                    )}
                    <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                        <fieldset>
                            <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                            <div style={{ marginTop: "20px" }}>
                                Security Questions are used to validate your identity when you login from an unrecognized IP address, reset your password or re-activate your account. Please set your
                                security questions and answers below
                            </div>
                            <div className="mb-3">
                                <DropDownList
                                    label="Security question 1"
                                    data={questions.slice(0, 4)}
                                    textField="description"
                                    dataItemKey="id"
                                    value={selectQuestionOne}
                                    onChange={this.onChangeQuestionOne}
                                />
                            </div>
                            <div className="mb-3">
                                <Input type="text" value={answerOne} onChange={this.handleChangeAnswerOne} />
                            </div>
                            <div className="mb-3">
                                <DropDownList
                                    label="Security question 2"
                                    data={questions.slice(4, 8)}
                                    textField="description"
                                    dataItemKey="id"
                                    value={selectQuestionTwo}
                                    onChange={this.onChangeQuestionTwo}
                                />
                            </div>
                            <div className="mb-3">
                                <Input type="text" value={answerTwo} onChange={this.handleChangeAnswerTwo} />
                            </div>
                            <div className="mb-3">
                                <DropDownList
                                    label="Security question 3"
                                    data={questions.slice(8, 12)}
                                    textField="description"
                                    dataItemKey="id"
                                    value={selectQuestionThree}
                                    onChange={this.onChangeQuestionThree}
                                />
                            </div>
                            <div className="mb-3">
                                <Input type="text" value={answerThree} onChange={this.handleChangeAnswerThree} />
                            </div>
                        </fieldset>

                        <Button type="button" themeColor={"primary"} style={{ width: "100%" }} onClick={this.handleConfirm}>
                            Confirm
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(SecurityQuestions);
