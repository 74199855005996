import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import "@progress/kendo-theme-default/dist/all.css";
import "@progress/kendo-theme-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./routes/Login";
import ForgotPassword from "./routes/ForgotPassword";
import Profile from "./routes/Profile";
//import Users from './routes/Users';
import SecurityQuestions from "./routes/SecurityQuestions";
import ResetPassword from "./routes/ResetPassword";
import ChangePassword from "./routes/ChangePassword";
import EmailNewUser from "./routes/EmailNewUser";
import ResetAccount from "./routes/ResetAccount";
import Sessions from "./routes/Sessions";
import UserSecurityQuestions from "./routes/UserSecurityQuestions";
import 'bootstrap/dist/css/bootstrap.css';
import "./custom.css";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`}>
                        <Login />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/password/`}>
                        <ForgotPassword />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/profile`}>
                        <Profile />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/security-questions`}>
                        <SecurityQuestions />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/user-security-questions`}>
                        <UserSecurityQuestions />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/change-password`}>
                        <ChangePassword />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/email-new-user`}>
                        <EmailNewUser />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/sessions`}>
                        <Sessions />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/reset-password`}>
                        <ResetPassword />
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/ResetAccount/:token`} component={ResetAccount} />
                </Switch>
            </Router>
        );
    }
}
