const cookieOptions = ";path=/;secure;SameSite=Strict";

class AuthService {

    constructor() {
        this.token = "";
        this.role = "";
        this.mail = "";
    }

    getCookie(cName) {
        var name = cName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setVariables(token, role, mail) {
        this.token = token;
        this.role = role;
        this.mail = mail;
        // this.setState({ token: token });
        // this.setState({ role: role });
        // this.setState({ mail: mail });
    }

    setCookie(cname, cValue) {
        // console.log("Setting cookie: " + cname + "=" + cValue + cookieOptions);
        // document.cookie = cname + "=" + cValue + ";path=/;secure;SameSite=Strict";
        document.cookie = cname + "=" + cValue + cookieOptions;
    }

    deleteCookie(cName) {
        // console.log("Deleting cookie: " + cName);
        // document.cookie = cName + "=;path=/;secure;SameSite=Strict;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = cName + "=" + cookieOptions + ";expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    async getUserToken() {
        if (this.token === "" || this.token === undefined || this.token === null) {
            var requestOptions = {
                method: "GET"
            };
            const response = await fetch("api/users/check", requestOptions);
            const data = await response.json();
            this.token = data.token;
            this.role = data.role;
            this.mail = data.user_email;
        }
        return this.token;
    }

    getUserRole() {
        return this.role;
    }

    getUserMail() {
        return this.mail;
    }

    async getUserInfo() {
        //console.log("token: " + this.getCookie("token"));
        // return this.getCookie("token");
        // return this.token;
        var token = await this.getUserToken();
        return token;
    }

    async getAuthHeader() {
        var token = await this.getUserToken();
        return new Headers({
            // Authorization: `Bearer ${this.getCookie("token")}`,
            Authorization: `Bearer ${token}`,
        });
    }
}

export default new AuthService();
