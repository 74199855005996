import React, { Component } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Link, withRouter } from "react-router-dom";
import logo from "../assets/logo.png";

// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS Login - Forgot Password";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
        };
    }

    componentDidMount() {
        document.title = pageTitle;
    }

    keyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleChangeEmail = (event) => {
        this.setState({ email: event.value });
    };

    handleSubmit = () => {
        let { email } = this.state;

        const data = new FormData();
        data.append("to", email);
        var requestOptions = {
            method: "POST",
            body: data,
        };

        if (!/\S+@\S+\.\S+/.test(email)) {
            window.alert("Please enter a valid email.");
            return;
        }

        this.setState({ loading: true });

        fetch("api/users/requestResetPassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                window.alert(result.message);
                this.setState({ email: "" });
                this.setState({ loading: false });
            })
            .catch((error) => {
                window.alert(error);
                this.setState({ loading: false });
            });
    };

    render() {
        const { email, loading } = this.state;
        return (
            <div className="login-center">
                <div className="panel-login center" style={{ marginTop: "3%" }}>
                    {loading && (
                        <div className="k-loading-mask" style={{ zIndex: 10000 }}>
                            <span className="k-loading-text">Loading</span>
                            <div className="k-loading-image"></div>
                            <div className="k-loading-color"></div>
                        </div>
                    )}
                    <form className={"k-form center"} onSubmit={e => { e.preventDefault(); }}>
                        <fieldset>
                            <img src={logo} alt={"Apex Logo"} style={{ width: "35%" }} />
                            <div style={{ marginTop: "20px" }}>We will send you a link to a page where you can easily reset your password.</div>
                            <div className="mb-3">
                                <Input onKeyDown={this.keyPress} type="Email or user account" label="Email" value={email} onChange={this.handleChangeEmail} />
                            </div>
                        </fieldset>

                        <Button type="button" themeColor={"primary"} style={{ width: "100%", marginBottom: "5px" }} onClick={this.handleSubmit}>
                            Send
                        </Button>
                        <Link to={`${process.env.PUBLIC_URL}`} style={{ textDecoration: "none" }}>
                            <Button type="button" style={{ width: "100%" }}>
                                Go back
                            </Button>
                        </Link>
                    </form>
                </div>
            </div>
        );
    }
}

// export default ForgotPassword;
export default withRouter(ForgotPassword);
