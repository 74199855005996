import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
import Auth from "../services/Auth";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withRouter } from "react-router-dom";
import logo from "../assets/logo.png";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

class NavMenu extends Component {
    logOut = async (event) => {
        // Auth.deleteCookie("token");
        Auth.token = "";
        var requestOptions = {
            method: "GET"
        };
        await fetch("api/users/logout", requestOptions);
        Auth.deleteCookie("redirect");
        this.props.history.push(baseUrl);
    };

    goToProfile = (event) => {
        this.props.history.push(baseUrl + "profile");
    };

    goToChangePassword = (event) => {
        this.props.history.push(baseUrl + "change-password");
    };

    goToEmailNewUser = (event) => {
        this.props.history.push(baseUrl + "email-new-user");
    };

    goToSessions = (event) => {
        this.props.history.push(baseUrl + "sessions");
    };

    isActiveClass = (path) => {
        return this.props.location.pathname.includes(path) ? "active" : "";
    };

    render() {
        return (
            <Navbar color="light" light expand="sm" className="navbar-menu">
                <NavbarBrand href="/">
                    <img src={logo} alt={"Apex Logo"} style={{ width: "56px", height: "45px", marginLeft: "10px" }} />
                </NavbarBrand>
                <Nav className="ms-auto" navbar>
                    <NavLink href="#" onClick={() => this.goToProfile()} className={this.isActiveClass("profile")}>My Sites</NavLink>
                    {Auth.getUserMail().toLowerCase().indexOf("apexcos.com") === -1 && (
                        <NavLink href="#" onClick={() => this.goToChangePassword()} className={this.isActiveClass("change-password")}>Change My Password</NavLink>
                    )}
                    {Auth.getUserRole() === "admin" && (
                        <NavLink href="#" onClick={() => this.goToEmailNewUser()} className={this.isActiveClass("email-new-user")}>Email New Users</NavLink>
                    )}
                    <NavLink href="#" onClick={() => this.goToSessions()} className={this.isActiveClass("sessions")}>Sessions</NavLink>
                    <NavLink href="#" onClick={() => this.logOut()}>Logout</NavLink>
                </Nav>
            </Navbar>
        );
    }
}

export default withRouter(NavMenu);
