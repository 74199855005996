import React, { Component } from "react";
import "babel-polyfill";
import "whatwg-fetch";
// import { Input } from "@progress/kendo-react-inputs";
// ES2015 module syntax
import { ListView, ListViewHeader, ListViewFooter } from "@progress/kendo-react-listview";
// import { parseDate } from '@progress/kendo-react-intl';
import Nav from "../components/Nav";
import Auth from "../services/Auth";
import { withRouter } from "react-router-dom";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const pageTitle = "ARTEMIS - My Open Sessions";

const MyHeader = () => {
    return <ListViewHeader style={{ color: "rgb(160, 160, 160)", fontSize: 14 }} className="pl-3 pb-2 pt-2"></ListViewHeader>;
};

// const MyFooter = () => {
//     return <ListViewFooter style={{ color: "rgb(160, 160, 160)", fontSize: 14 }} className="pl-3 pb-2 pt-2"></ListViewFooter>;
// };

function formatDate(date) {
    //Add missing UTC timezone
    // date += "0Z";
    return new Date(date).toLocaleString()
}

async function revokeSession(uid) {
    const data = new FormData();
    data.append("uid", uid);

    var authHeader = await Auth.getAuthHeader();
    var requestOptions = {
        method: "POST",
        body: data,
        headers: authHeader
    };

    // this.setState({ loading: true });

    fetch("api/users/revokeUserSession", requestOptions)
        .then((res) => res.json().then((data) => ({ status: res.status, response: data })))
        .then((result) => {
            if (result.status === 200) {
                window.location.reload();
            } else {
                if (result.response.message) {
                    window.alert(result.response.message);
                } else {
                    window.alert("There was a problem ending this session.");
                }
            }
            // this.setState({ loading: false });
        })
        .catch((error) => {
            window.alert(error);
            // this.setState({ loading: false });
        });

}

const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
        <div className={`list-group-item list-group-item-action session-div ${item.uid === item.currentUserId ? "session-active" : ""}`} title={item.uid}>
            <strong>IP Address:</strong> {item.ipAddress}<br />
            <strong>Created:</strong> {formatDate(item.createDate)}<br />
            <strong>Expires:</strong> {formatDate(item.expireDate)}<br />
            {/* <strong>UID:</strong> {item.uid}<br /> */}
            {/* <div className="revoke-session-top-right">
                <button onClick={() => revokeSession(item.uid)} border="0" title="End Session" className="revoke-session-button"><span className="revoke-session-icon far fa-times-circle"></span></button>
            </div> */}
            {item.uid !== item.currentUserId && (
                <div className="revoke-session-top-right">
                    <button onClick={() => revokeSession(item.uid)} border="0" title="End Session" className="revoke-session-button"><span className="revoke-session-icon far fa-times-circle"></span></button>
                </div>
            )}
        </div>
    );
};

class Session extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            currentIP: "",
            uid: "",
            redirect: false,
            loading: false,
        };
    }

    async componentDidMount() {
        let token = await Auth.getUserInfo();
        if (token) {
            this.getUserSessions();
        } else {
            this.setState({ redirect: true });
        }
        document.title = pageTitle;
    }

    async getUserSessions() {
        var authHeader = await Auth.getAuthHeader();
        var requestOptions = {
            method: "GET",
            headers: authHeader
        };

        this.setState({ loading: true });

        fetch("api/users/getUserSessions", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                this.setState({ sessions: result.sessions });
                this.setState({ currentIP: result.currentIP });
                this.setState({ uid: result.uid });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log("Session Error");
                this.setState({ redirect: true });
                this.setState({ loading: false });
            });
    }

    render() {
        const { loading, redirect, sessions, currentIP, uid } = this.state;

        if (redirect) {
            this.props.history.push(baseUrl);
        }

        const sessionsWithUid = sessions.map(session => ({ ...session, currentUserId: uid }));

        return (
            <div>
                <Nav />
                <div className="col-xs-12 col-md-6 offset-md-3">
                    <br />
                    <div className="card">
                        {loading && (
                            <div className="k-loading-mask">
                                <span className="k-loading-text">Loading</span>
                                <div className="k-loading-image"></div>
                                <div className="k-loading-color"></div>
                            </div>
                        )}
                        <div className="card-block">
                            <form className={"k-form"} onSubmit={e => { e.preventDefault(); }}>
                                <fieldset>
                                    <legend style={{ textAlign: "center" }}>My Open Sessions</legend>
                                    {sessions.length === 0 && <p>You do not have access any open sessions</p>}
                                    {sessions.length > 0 && (
                                        <div className="mb-3">
                                            <div className="list-group">
                                                {/* <ListView data={sessions} item={MyItemRender} style={{ width: "100%" }} header={MyHeader} /> */}
                                                <ListView data={sessionsWithUid} item={MyItemRender} style={{ width: "100%" }} header={MyHeader} />
                                                <ListViewFooter style={{ color: "rgb(160, 160, 160)", fontSize: 14, textAlign: "center" }} className="pl-3 pb-2 pt-2"><strong>Current IP Address:</strong> {currentIP}</ListViewFooter>
                                            </div>
                                        </div>
                                    )}
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Session);
